<template>
  <div class="timeline-wrapper overflow-auto">
    <div v-if="timelineItems" class="timeline">
      <div
        v-for="(item, key) in timelineItems.slice().reverse()"
        :key="key"
        :class="[key % 2 ? 'timeline-item even' : 'timeline-item odd']"
      >
        <div class="content">
          <div class="timestamp">
            <span class="date">{{ formatDate(item.enddate) }}</span>
          </div>
          <TimelineCard :item-timeline="item" :add-link="addLink" />
        </div>
      </div>
    </div>
    <div v-else>
      <Loading :messageWhenNoItems="messageWhenNoItems"></Loading>
    </div>
  </div>
</template>
<script>
import TimelineCard from './TimelineCard.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'Timeline',
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  components: {
    TimelineCard,
    Loading,
  },
  props: {
    timelineItems: null,
    addLink: {
      type: Boolean,
    },
    order: {
      type: String,
      default: '',
    },
    messageWhenNoItems: {
      type: String,
      default: 'Loading articles...',
    },
  },
  mounted() {},
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'long',
      }
      return new Date(date).toLocaleDateString('en-GB', options)
    },
  },
}
</script>
<style scoped lang="scss">
.timeline-wrapper {
  list-style-type: none;
  padding: 0;
  padding-bottom: 0px;
  width: 80vw;
  height: 85vh;
  position: relative;
  max-width: 1200px;
  scrollbar-width: none;
}

.timeline {
  position: relative;
  max-width: 1200px;

  @media screen and (min-width: 1024px) {
    width: 60vw;
  }

  .content {
    background-color: white;
    display: flex;
    justify-content: center;
    position: relative;
    text-transform: none;
  }

  & * {
    box-sizing: border-box;
  }

  /* top line for vertical bar */
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    left: calc(- 20px);
    background-color: black;
    top: calc(0%);
    z-index: 1;

    @media screen and (min-width: 1024px) {
      left: 50%;
    }
  }

  /* timeline vertical bar */
  &::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: black;
    top: 0;
    bottom: 0;
    left: 20px;
    margin-left: -3px;

    @media screen and (min-width: 1024px) {
      left: 50%;
    }
  }

  /* timeline item */
  &-item {
    text-align: center;
    font-size: 12px;
    padding: 20px 0px 20px 0px;
    margin-left: 50px;
    position: relative;
    width: 75%;

    @media screen and (min-width: 520px) {
      margin-left: 80px;
      width: 60%;

      @media screen and (min-width: 1024px) {
        padding: 10px 40px 10px 40px;
        width: 50%;
        margin-left: 0%;
        font-size: 14px;
      }
    }

    /* timeline item circle icon */
    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: calc(-39px);
      background-color: white;
      border: 3px solid black;
      top: calc(50% - 5px);
      border-radius: 50%;
      z-index: 1;

      @media screen and (min-width: 520px) {
        left: calc(-69px);

        @media screen and (min-width: 1024px) {
          left: calc(100% - 10px);
        }
      }
    }
    /* odd elements */
    &:nth-child(odd) {
      left: 0;

      @media screen and (min-width: 1024px) {
        padding: 10px 40px 10px 0px;
      }

      /* odd horizontal stick */
      @media screen and (min-width: 520px) {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: calc(-69px);
          width: 50%;
          z-index: 0;
          height: 3px;
          background-color: black;

          @media screen and (min-width: 1024px) {
            left: 50%;
          }
        }
      }

      /* odd content */
      .content {
        @media screen and (min-width: 520px) {
          /* odd dot */
          &::before {
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            left: calc(0% - 5px);
            background-color: black;
            border: 0px solid black;
            top: calc(50% - 3px);
            border-radius: 50%;
            z-index: 1;

            @media screen and (min-width: 1024px) {
              left: calc(100% - 5px);
            }
          }
        }
      }

      /* odd timestamp */
      .timestamp {
        @media screen and (min-width: 1024px) {
          left: calc(100% + 80px);
          top: calc(50% - 5px);
        }
      }
    }

    /* even elements */
    &:nth-child(even) {
      @media screen and (min-width: 1024px) {
        padding: 10px 0px 10px 40px;
      }

      /* even horizontal stick */
      @media screen and (min-width: 520px) {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: calc(-69px);
          width: 50%;
          z-index: 0;
          height: 3px;
          background-color: black;
          @media screen and (min-width: 1024px) {
            left: 0;
          }
        }
      }

      /* even content shifted right */
      @media screen and (min-width: 1024px) {
        left: 50%;
      }

      .content {
        @media screen and (min-width: 520px) {
          /* even dot */
          &::before {
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            left: calc(0% - 5px);
            background-color: black;
            border: 0px solid black;
            top: calc(50% - 3px);
            border-radius: 50%;
            z-index: 1;
            @media screen and (min-width: 1024px) {
              left: calc(0% - 5px);
            }
          }
        }

        @media screen and (min-width: 1024px) {
        }
      }

      /* even timestamp */
      .timestamp {
        @media screen and (min-width: 1024px) {
          left: calc(-80px - 120px);
          top: calc(50% - 5px);
          text-align: right;
        }
      }

      &::after {
        @media screen and (min-width: 1024px) {
          left: -10px;
        }
      }
    }
  }
}
.timestamp {
  width: 120px;
  text-align: left;
  position: absolute;
  left: 0px;
  top: -20px;
}
</style>
