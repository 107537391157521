<template>
  <h1 class="Title">Milestones</h1>
  <Timeline
    :timeline-items="sortedMilestones"
    :message-when-no-items="messageWhenNoItems"
    :add-link="timelineLink"
  />
</template>
<script>
import Timeline from '@/components/timeline/Timeline.vue'
import MilestonesAPI from '@/api/milestones'

export default {
  name: 'Milestones',
  components: {
    Timeline,
  },
  data() {
    return {
      messageWhenNoItems: 'Loading milestones...',
      milestones: [],
      timelineLink: false,
    }
  },
  computed: {
    sortedMilestones() {
      return this.milestones
        .slice()
        .sort((a, b) => new Date(a.enddate) - new Date(b.enddate))
    },
  },
  created() {
    MilestonesAPI.getAll()
      .then((response) => (this.milestones = response.data))
      .catch((error) => console.log(error))
  },
}
</script>
<style></style>
