<template>
  <div class="card bg-light">
    <router-link
      v-if="addLink === true"
      :to="'/project/' + itemTimeline.id"
      style="text-decoration: none; color: inherit"
    >
      <div class="card-body">
        <h5 class="card-title">{{ itemTimeline.title }}</h5>
        <p class="card-text fulltext sentence_case text-break text-start">
          {{ truncateDescription(itemTimeline.description, 1300) }}
        </p>
        <p class="cardcaption">
          <em>Read more</em>
        </p>
      </div>
    </router-link>
    <div v-else class="card-body">
      <h5 class="card-title">{{ itemTimeline.title }}</h5>
      <p class="card-text fulltext sentence_case text-break text-start">
        {{ truncateDescription(itemTimeline.description, 1300) }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TimelineItem',
  props: {
    itemTimeline: {
      type: Object,
      default: () => ({}),
    },
    addLink: {
      type: Boolean,
    },
  },
  methods: {
    truncateDescription(value, extent) {
      return value.length > extent ? value.slice(0, extent) + '...' : value
    },
  },
}
</script>
<style>
.cardcaption {
  text-align: right;
  position: absolute;
  right: 16px;
  bottom: 0px;
}
</style>
